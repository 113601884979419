/* body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
} */

@font-face {
  font-family: JetBrainsMono-Light;
  src: url("./fonts/JetBrainsMono-Light.woff2");
}

@font-face {
  font-family: JetBrainsMono-Bold;
  src: url("./fonts/JetBrainsMono-Bold.woff2");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-[JetBrainsMono-Light];
  }

  body {
    @apply bg-black;
    @apply text-white;
  }
}

@layer components {
  .container {
    @apply min-w-[1200px] max-w-[1320px] px-[1.25rem] lg:px-[1.875rem] xl:px-[3.75rem] mx-auto;
  }

  .text {
    @apply font-[JetBrainsMono-Light];
  }

  .title {
    @apply font-[JetBrainsMono-Bold];
  }
}
